<template>
  <div v-if="users.length > 0">
    <v-alert v-model="alert" dismissible color="warning" icon="warning">
      <h4 class="alert-heading">{{ $t("USER_CONTRACT") }}</h4>

      <div class="container-user-contracts">
        <div class="col-user-contracts" v-for="column in columns">
          <div class="item-container-user-contracts" v-for="item in column">
            <router-link
              :to="{
                name: 'edit-user',
                params: { id: item.id }
              }"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </v-alert>
  </div>
</template>
<style>
.container-user-contracts {
  display: flex;
}

.col-user-contracts {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
<script>
//Fetch Users Contract
import { mapGetters } from "vuex";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { GET_USERS_CONTRACT } from "../../../modules/user/store/user.module";

export default {
  name: "UserAlertBox",
  mixins: [permissionMixin],
  data() {
    return {
      cols: 5,
      alert: true,
      users: []
    };
  },
  mounted() {
    let vm = this;
    vm.getUserContracts();
  },
  computed: {
    columns() {
      console.log(this.users.length);
      if (this.users.length === 0) return [];
      let columns = [];
      let mid = Math.ceil(this.users.length / this.cols);
      for (let col = 0; col < this.cols; col++) {
        columns.push(this.users.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
    ...mapGetters(["getUsers"])
  },
  methods: {
    getUserContracts() {
      let vm = this;
      this.$store
        .dispatch(GET_USERS_CONTRACT)
        .then((data) => {
          vm.$nextTick(function () {
            vm.users = data.data;
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    }
  }
};
</script>
